import React from "react";
import { Overlay } from "../Overlay";
import { GatsbySanityImage } from "../GatsbySanityImage";
import {
  BannerInnerContentWrapper,
  BannerIconWrapper,
  BannerContent,
} from "./elements";

export const Banner = ({ banner, styles = {}, isGatsbyImage }) => {
  if (!banner) return null;
  return (
    <BannerContent variant={banner?.bannerSize} style={styles}>
      <Overlay className="banner-overlay" />
      {banner?.sectionHeroImage &&
        (isGatsbyImage ? (
          <GatsbySanityImage
            gatsbyImage={banner?.sectionHeroImage?.asset?.gatsbyImageData}
          />
        ) : (
          <GatsbySanityImage
            className="villaBoundlessBanner"
            gatsbyImage={banner?.sectionHeroImage?.asset?.gatsbyImageData}
          />
        ))}
      <BannerInnerContentWrapper>
        {banner?.sectionIconBadge?.asset ? (
          <BannerIconWrapper>
            <GatsbySanityImage
              mainWrapperStyle={{ width: "auto" }}
              gatsbyImage={banner?.sectionIconBadge?.asset?.gatsbyImageData}
            />
          </BannerIconWrapper>
        ) : (
          <BannerText
            title={banner?.BannerTitle}
            subTitle={banner?.descriptionShort}
          />
        )}
      </BannerInnerContentWrapper>
    </BannerContent>
  );
};

const BannerText = ({ isBorders, title, subTitle }) => {
  return (
    <>
      {isBorders ? (
        <>
          <div className="top-border" />
          <h2>{title}</h2>
          <div className="bottom-border" />
        </>
      ) : (
        <>
          <div className="title">
            <h3>{title}</h3>
          </div>
          <div className="sub-title">{subTitle}</div>
        </>
      )}
    </>
  );
};
