import React from "react";
import { FormControl } from "./elements";
export const TextField = React.forwardRef((props, ref) => {
  const {
    wrapperClassName,
    labelText,
    error,
    isRequired,
    variant,
    ...inputProps
  } = props;
  return (
    <FormControl className={wrapperClassName}>
      <label>
        {labelText}
        {isRequired && <span className="required">*</span>}
      </label>
      {variant === "textarea" ? (
        <textarea ref={ref} {...inputProps} />
      ) : (
        <input ref={ref} {...inputProps} />
      )}
      {error ? <ErrorField error={error} /> : null}
    </FormControl>
  );
});

const ErrorField = ({ error }) => {
  return <span className="error">{error?.message}</span>;
};
