import styled from "styled-components";
import { device } from "../../styles/deviceSizes";

export const StyledModel = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1001;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  width: ${({ isOpen }) => (isOpen ? "100%" : 0)};
`;

export const ModalDialog = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  width: ${({ isOpen }) => (isOpen ? "70rem" : 0)};
  background: #fffdfd;
  /* width: 60rem; */
  /* -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  -webkit-transition: width 0.3s linear, right 0.3s ease-out;
  -moz-transition: width 0.3s linear, right 0.3s ease-out;
  -o-transition: width 0.3s linear, right 0.3s ease-out;
  transition: width 0.3s linear, right 0.3s ease-out; */

  animation: ${({ isOpen }) =>
    `${isOpen ? "inAnimation" : "outAnimation"} .5s ease-in-out`};
  /* transition: opacity 0.3s linear, right 0.3s ease-out; */
  @keyframes inAnimation {
    0% {
      opacity: 0;
      width: 0;
      visibility: hidden;
    }

    50% {
      opacity: 50%;
      visibility: visible;
    }
    100% {
      opacity: 1;
      width: 70rem;
      visibility: visible;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
      width: 70rem;
      overflow: hidden;
    }

    100% {
      width: 0;
      opacity: 1;
      visibility: hidden;
      overflow: hidden;
    }
  }

  @media ${device.onlyTableSm} {
    width: 100%;
  }
`;

export const ModalBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ModalContent = styled.div`
  height: 100%;
  overflow-y: auto;
  background: #fffdfd;

  ::-webkit-scrollbar {
    width: 1rem;
    @media ${device.onlyMobileSm} {
      width: 0.5rem;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #efefef;
  }

  ::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    background-color: var(--grey73);
    height: 2px;
    border: 1px solid transparent;
    background-clip: content-box;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 1rem;
  cursor: pointer;
  border-radius: 50%;

  @media ${device.onlyMobileSm} {
    width: 24px;
    height: 24px;
  }
  svg {
    path {
      stroke: white;
    }
    stroke-width: 10px;
    width: ${({ dimension }) => (dimension ? dimension : "15px")};
    height: ${({ dimension }) => (dimension ? dimension : "15px")};
    @media ${device.onlyMobileSm} {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ModalHeader = styled.div`
  /* background-color: #76622e; */
  background-color: var(--black);
  position: ${({ isStickyHeader }) => (isStickyHeader ? "sticky" : "relative")};
  top: ${({ isStickyHeader }) => (isStickyHeader ? 0 : "unset")};
  z-index: ${({ isStickyHeader }) => (isStickyHeader ? 5 : 1)};
  filter: ${({ isStickyHeader }) =>
    isStickyHeader ? "drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))" : "none"};

  width: 100%;
  height: 7rem;

  color: white;
  display: flex;
  align-items: center;
  opacity: 0.9;
  h4 {
    font-size: 2.5rem;
    color: white;
    width: 100%;
    text-align: center;
    align-self: center;
    @media ${device.onlyMobileSm} {
      font-size: 1.4rem !important;
    }
  }
`;

export const PricingModalContentWrapper = styled.div`
  display: grid;
  padding: 0.5rem 5rem 5rem 5rem;
  grid-template-columns: 1fr 1fr;

  @media ${device.desktopS} {
    grid-template-columns: 1.5fr 1fr;
  }
  grid-gap: 20px;
  img {
    height: 50%;
    margin-top: 52px;
  }
  .detail-section {
    display: flex;
    flex-direction: column;
  }
  .note-text-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5rem;
    align-items: center;
    h5 {
      align-self: flex-start;
      font-weight: bold;
      color: #76622e;
    }
  }
`;

export const PricingModalHeader = styled.div`
  h2 {
    font-size: 30px;
    color: #76622e;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
  }
`;
