import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
export const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    width: 100%;
  }

  input,
  select {
    @supports (-webkit-touch-callout: none) {
      -webkit-appearance: none;
    }

    width: 100%;
    padding: 1rem 1.5rem;
  }

  textarea {
    @supports (-webkit-touch-callout: none) {
      -webkit-appearance: none;
    }
    height: 15rem;
  }

  label {
    text-transform: uppercase;
    color: #3d3d3d;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    @media ${device.onlyMobileSm} {
      font-size: 1.2rem;
    }
  }
  .error {
    font-size: 1.6rem;
    color: red;
    margin-top: 1rem;
    text-align: left;
  }
`;
