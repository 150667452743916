import React from "react";
import ReactDatePicker from "react-datepicker";
import CalendarIcon from "../../../assets/icons/calendar.svg";
import PhoneInput from "../../PhoneInput/PhoneInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import emailJs from "@emailjs/browser";
import { Modal, TextField, Button } from "../../../components";
import countriesData from "../../../lib/countries";
import { ControlledReactSelect } from "../PricingModal/ControlledReactSelect";
import {
  SvgWrapper,
  DatePickerWrapper,
  ContactUsModalWrapper,
} from "./elements";

const validationSchema = yup
  .object({
    email: yup.string().email("Email is not valid").required("Required*"),
    message: yup.string().required("Required*"),
  })
  .required();

const budgetRanges = [
  { label: "$3,500 - $5,000", value: "$3,500 - $5,000" },
  { label: "$5,000 - $7,500", value: "$5,000 - $7,500" },
  { label: "$7,500 - $10,000", value: "$7,500 - $10,000" },
  { label: "$10,000 - 15,000", value: "$10,000 - 15,000" },
  { label: "$15,000 - $20,000", value: "$15,000 - $20,000" },
  { label: "$20,000 - $25,000", value: "$20,000 - $25,500" },
  { label: "$25,000 - $30,000", value: "$25,000 - $30,000" },
  { label: "$30,000 - $35,000", value: "$30,000 - $35,000" },
  { label: "Above $35,000", value: "Above $35,000" },
];

const subjectOptions = [
  {
    label: "Booking inquiry",
    value: "Booking inquiry",
  },
  {
    label: "Itinerary planning",
    value: "Itinerary planning",
  },
  {
    label: "Special requests",
    value: "Special requests",
  },
  {
    label: "Group travel",
    value: "Group travel",
  },
  {
    label: "Existing booking",
    value: "Existing booking",
  },
  {
    label: "General Enquiry",
    value: "General Enquiry",
  },
];

const contactMeOptions = [
  {
    label: "Phone call",
    value: "Phone call",
  },
  {
    label: "Whatsapp",
    value: "Whatsapp",
  },
  {
    label: "Telegram",
    value: "Telegram",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
];
const customStyles = {
  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: "1.2rem",
    };
  },
};

export const ContactUsModal = ({ isOpen, handleClose, isMakeCall }) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { bestTime: new Date() },
  });

  const onSubmit = (formValues, e) => {
    e.preventDefault();
    emailJs
      .send(
        `${process.env.GATSBY_EMAIL_SERVICE_KEY}`,
        `${process.env.GATSBY_EMAIL_TEMPLATE_ID}`,
        formValues,
        `${process.env.GATSBY_EMAIL_PUBLIC_KEY}`
      )
      .then(
        () => {
          handleClose();
          reset();
          alert("Email sent successfully");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const { message, email } = errors;

  const allCountries = countriesData?.map(({ code, name }) => ({
    label: name,
    value: code,
  }));

  return (
    <ContactUsModalWrapper>
      <Modal
        isOpen={isOpen}
        alignContentCenter
        isStickyHeader
        handleClose={handleClose}
        headerText={isMakeCall ? `Request a call` : `Contact us`}
        variant="small"
        renderBodyContent={() => {
          return (
            <form
              className="contact-us-form"
              onSubmit={(e) => handleSubmit(onSubmit)(e)}
            >
              <TextField labelText="Your Name" {...register("name")} />
              <TextField
                labelText="Your Email"
                error={email}
                isRequired
                {...register("email")}
              />
              <div className="form-control mb-2">
                <label>Phone Number</label>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className="mt-1"
                      country="gb"
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      specialLabel=""
                    />
                  )}
                />
              </div>
              {isMakeCall ? (
                <React.Fragment>
                  <div className="form-control mb-2">
                    <label>Country of residence</label>
                    <ControlledReactSelect
                      fieldName="countryOfResidence"
                      className="mt-1 react-select"
                      placeholder="Select country of residence..."
                      control={control}
                      options={allCountries}
                      customStyles={customStyles}
                    />
                  </div>

                  <div className="form-control mb-2">
                    <label>Budget</label>
                    <ControlledReactSelect
                      fieldName="budget"
                      className="mt-1 react-select"
                      placeholder="Select the budget..."
                      control={control}
                      options={budgetRanges}
                      customStyles={customStyles}
                    />
                  </div>
                  <DatePickerWrapper className="form-control mb-2">
                    <Controller
                      control={control}
                      name="bestTime"
                      render={({ field: { onChange, value } }) => (
                        <ReactDatePicker
                          showTimeSelect
                          fixedHeight
                          popperModifiers={{
                            name: "offset",
                            options: {
                              offset: [0, -20],
                            },
                          }}
                          value={value}
                          selected={value}
                          dateFormat={"d MMMM yyyy - hh:mm"}
                          customInput={<CustomInput />}
                          onChange={onChange}
                        />
                      )}
                    />
                  </DatePickerWrapper>
                </React.Fragment>
              ) : null}
              <div className="form-control mb-2">
                <label>Select the Subject</label>
                <ControlledReactSelect
                  fieldName="subject"
                  className="mt-1 react-select"
                  placeholder="Select the Subject"
                  control={control}
                  options={subjectOptions}
                  customStyles={customStyles}
                />
              </div>
              <div className="form-control mb-2">
                <label>Contact me by</label>
                <ControlledReactSelect
                  fieldName="contactMe"
                  className="mt-1 react-select"
                  placeholder="Select your preferred contact method"
                  control={control}
                  options={contactMeOptions}
                  customStyles={customStyles}
                />
              </div>
              <TextField
                labelText="Please describes your query"
                variant="textarea"
                isRequired
                error={message}
                {...register("message")}
              />

              <Button
                type="submit"
                className="submit-btn"
                style={{ background: "var(--black)", color: "#fff" }}
              >
                Submit
              </Button>
            </form>
          );
        }}
      ></Modal>
    </ContactUsModalWrapper>
  );
};

const CustomInput = ({ value, onClick, ref }) => {
  return (
    <div
      // ref={ref}
      className="form-control"
      style={{
        postion: "relative",
        display: "flex",
        flexDirection: "column",
        marginBottom: "0",
      }}
    >
      <label>Select The Best Time</label>
      <div className="input" onClick={onClick}>
        <div style={{ position: "absolute", right: 10, bottom: 10 }}>
          <SvgWrapper>
            <CalendarIcon />
          </SvgWrapper>
        </div>
        <div>{value}</div>
      </div>
    </div>
  );
};
