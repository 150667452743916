import React from "react";
import { components } from "react-select";
import { Button } from "../../../Button";
import { ChildAgeFields, CounterInput, ControlledDatePicker } from "./Inputs";
import CloseIcon from "../../../../assets/icons/close.svg";
import { ControlledReactSelect } from "../ControlledReactSelect";
import { GatsbySanityImage } from "../../../GatsbySanityImage";
import { VillaIcons } from "../../../Villa/VillaIcons";
import { ResortCollectionIcons } from "../../../Collections";
import { StyleRoomCard, SelectOptionWrapper } from "./elements";
import { SvgWrapper } from "../elements";
import { useEffect } from "react";
import { GLOBAL, RESORT_MODE } from "../../../../constants";

const Option = (props) => {
  const content = props.data?.room;
  const isResort = props?.data?.isResort;
  const imageAsset = !isResort
    ? content?.imageThumb?.asset
    : content?.image?.asset;
  const gatsbyImage = imageAsset?.gatsbyImageData;
  const isSelected = props.isSelected;

  return (
    <components.Option {...props}>
      <SelectOptionWrapper>
        <div style={{ display: "flex" }}>
          <GatsbySanityImage
            gatsbyImage={gatsbyImage}
            assetId={imageAsset?._id}
            imageConfig={{ maxWidth: 300, placeholder: "blurred" }}
          />
        </div>
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <h4
            className="mt-1"
            style={{
              textAlign: "start",
              borderBottom: "1px solid #BABABA",
              paddingBottom: "1rem",
            }}
          >
            {content?.name}
          </h4>
          {!isResort ? (
            <VillaIcons
              villa={props?.data?.room}
              wrapperClassName="icons"
              labelClassName="icon_label"
              hideIcons={["shower"]}
            />
          ) : (
            <ResortCollectionIcons content={content} />
          )}
          <div>
            <Button
              isSelected={isSelected}
              hoverColor="var(--darkGrey)"
              styles={{ width: "100%" }}
            >
              Select
            </Button>
          </div>
        </div>
      </SelectOptionWrapper>
    </components.Option>
  );
};

const customOptionStyles = {
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      zIndex: 2,
      ":active": {
        backgroundColor: undefined,
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      marginTop: 0,
      zIndex: 2,
    };
  },
  menuList: (styles) => ({
    ...styles,
    maxHeight: "400px",

    "&::-webkit-scrollbar": {
      width: "1rem",
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "#EFEFEF",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "var(--grey73)",
      height: "2px",
      border: "1px solid transparent",
      backgroundClip: "content-box",
    },
  }),
  singleValue: (styles) => ({ ...styles, textAlign: "center" }),
  control: (styles) => ({
    ...styles,
    border: "unset",
    boxShadow: "unset",
    borderColor: "unset",
    justifyContent: "center",
    "&:hover": {
      border: "unset",
      boxShadow: "unset",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    flex: "unset",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: 0,
    background: "red",
  }),
};

export const RoomCard = ({
  index,
  item,
  getValues,
  isLastItem,
  setValue,
  control,
  elementRef,
  remove,
  allRooms: defaultRooms,
  pricingMode,
  allResorts,
}) => {
  const childrens = getValues(`rooms[${index}].childrens`);
  const adults = getValues(`rooms[${index}].adults`);
  const dateOfTravel = getValues(`rooms.[${index}].dateOfTravel`);
  const selectedResort = getValues(`rooms[${index}].resort`);

  useEffect(() => {
    if (selectedResort?._id) {
      setValue(`rooms[${index}].room`, undefined);
    }
  }, [selectedResort?._id]);

  const room = item?.room;
  const isPriceOnRequest = !!room?.priceOnRequest;
  const monthlyPricing = !!isPriceOnRequest
    ? null
    : room?.rateModel?.monthlyPricing;

  const defaultRooms_ = defaultRooms
    ?.filter(({ active }) => !!active)
    .map((room) => ({
      value: room?._id,
      label: room?.name,
      room,
    }));

  const allResorts_ = allResorts?.length
    ? allResorts?.map((resort) => {
        return {
          value: resort?._id,
          label: resort?.name,
          room: { ...resort },
          isResort: true,
        };
      })
    : [];

  const allRooms__ = selectedResort?.villas?.length
    ? selectedResort?.villas
    : [];

  const allRooms = allRooms__
    .filter(({ active }) => !!active)
    .map((room) => {
      return {
        label: room?.name,
        value: room?._id,
        room,
      };
    });

  const isGlobalMode = pricingMode === GLOBAL;
  const isResortMode = pricingMode === RESORT_MODE;
  // const isVillaMode = pricingMode === VILLA_MODE;
  const roomOptions = isGlobalMode ? allRooms : defaultRooms_;
  return (
    <StyleRoomCard ref={isLastItem ? elementRef : undefined} key={item?.id}>
      {index > 0 ? (
        <div className="close-icon-wrapper" title="Delete this room">
          <SvgWrapper
            dimension="1.4rem"
            onClick={() => {
              remove(index);
            }}
          >
            <CloseIcon />
          </SvgWrapper>
        </div>
      ) : null}
      <div
        className="form-control mt-1"
        style={{ borderBottom: "1px solid var(--grey73)" }}
      >
        <div>
          {!isGlobalMode ? (
            <div className="resort-name">
              {room?.resort?.name || defaultRooms?.[0]?.resort?.name}
            </div>
          ) : (
            <ControlledReactSelect
              control={control}
              fieldName={`rooms[${index}].resort`}
              placeholder="Select resort..."
              className="react-select"
              options={allResorts_}
              customOnChange={(onChange, option) => {
                const { label, value, room } = option;
                onChange({ label, value, ...room });
              }}
              // menuIsOpen
              components={{ Option }}
              customStyles={customOptionStyles}
            />
          )}
          {index !== 0 || isGlobalMode || isResortMode ? (
            <ControlledReactSelect
              control={control}
              fieldName={`rooms[${index}].room`}
              placeholder="Select room..."
              className="react-select"
              options={roomOptions}
              customOnChange={(onChange, option) => {
                const { label, value, room } = option;
                onChange({ label, value, ...room });
              }}
              components={{ Option }}
              customStyles={customOptionStyles}
              // menuIsOpen
            />
          ) : (
            <div className="room-name">
              {room?.name || defaultRooms_[0]?.label}
            </div>
          )}
          <div className="room-code">
            {room?.uniqueCode ? `#${room?.uniqueCode}` : null}
          </div>
        </div>
      </div>
      <div
        key={item?.id}
        className="individual mb-1"
        style={{
          padding: "2rem 0rem",
        }}
      >
        <ControlledDatePicker
          fieldName={`rooms[${index}].dateOfTravel`}
          dateOfTravel={dateOfTravel}
          monthlyPricing={monthlyPricing}
          control={control}
        />
        <div
          className="mb-1 d-flex flexible-dates"
          style={{ marginTop: "2rem" }}
        >
          <input type="checkbox" className="d-flex" id="flexible-dates" />
          <label for="flexible-dates">Dates Flexible</label>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "4rem",
            marginTop: "2rem",
            fontSize: "1.4rem",
          }}
        >
          <CounterInput
            wrapperClassName="form-control mb-1"
            valueLabel="Adults"
            label="Number of adults"
            value={adults}
            onPlusClick={() => {
              setValue(`rooms[${index}].adults`, adults + 1);
            }}
            onMinusClick={() => {
              if (adults > 0) setValue(`rooms[${index}].adults`, adults - 1);
            }}
          />
          <CounterInput
            wrapperClassName="form-control mb-1"
            valueLabel="Childrens"
            label="Number of childrens"
            value={childrens}
            onPlusClick={() => {
              setValue(`rooms[${index}].childrens`, childrens + 1);
            }}
            onMinusClick={() => {
              if (childrens > 0)
                setValue(`rooms[${index}].childrens`, childrens - 1);
            }}
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: "2rem",
            marginTop: "2rem",
            fontSize: "1.4rem",
          }}
        >
          <ChildAgeFields
            control={control}
            nestIndex={index}
            childrens={childrens}
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </div>
    </StyleRoomCard>
  );
};
