import styled from "styled-components";
import { device } from "../../../../styles/deviceSizes";
export const StyleRoomCard = styled.div`
  border: 1px solid var(--grey73);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  margin-bottom: 2rem;
  position: relative;
  .close-icon-wrapper {
    position: absolute;
    top: 0.7rem;
    right: 0.2rem;
    z-index: 1;
  }
  .resort-name,
  .room-name {
    text-align: center;
    @media ${device.onlyMobileSm} {
      font-size: 1.4rem;
    }
  }

  .room-code {
    text-align: center;
    color: var(--darkGrey);
    opacity: 0.8;
    font-size: 1.2rem;
    @media ${device.onlyMobileSm} {
      font-size: 1rem;
    }
  }
  .flexible-dates {
    margin-top: 2rem;

    input {
      height: 1.6rem;
      width: 1.6rem;
      margin: unset;
      @media ${device.onlyMobileSm} {
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    input[type="checkbox"] {
      accent-color: var(--black);
    }

    label {
      font-size: 1.4rem;
      margin-left: 0.5rem;
      color: #000000;
      @media ${device.onlyMobileSm} {
        font-size: 1.2rem;
      }
    }
  }
`;

export const SelectOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  border: 1px solid #bababa; /* justify-content: space-between; */
  padding: 2rem;
  align-items: center;
  .gatsby-image-wrapper {
    height: 21rem;
    cursor: pointer;
  }

  h4 {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #76622e;
    font-weight: 400 !important;
    width: 100%;
    text-align: left;
    margin: 1rem 0rem 1rem 0 !important;
    text-align: center;
    cursor: pointer;
  }
  .icons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    font-size: 1.2rem;
    text-align: left;
    color: #fff;
    align-items: baseline;
    /* padding: 0 2rem; */
    svg {
      height: 25px;
      width: 25px;
    }

    @media ${device.onlyMobileSm} {
      justify-content: flex-start;
      width: 100%;
    }

    &.resorts {
      grid-template-columns: 1fr 1fr 1fr;
      /* margin-bottom: 2rem; */
      .icon_label {
        text-transform: uppercase;
        font-weight: bold;
      }
      p {
        font-size: 1rem;
        color: #787878;
      }

      @media ${device.onlyMobileSm} {
        width: 100%;
        gap: 1rem;
        margin: 1.5rem 0rem;
      }
    }

    @media ${device.onlyMobileS} {
      grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
    }

    li {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon_label {
        display: inline-block;
        width: auto;
        font-size: 1rem;
        font-weight: bold;
        color: #787878;
        text-align: center;
        margin-top: 10px;
      }
      .inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export const CustomDatepickerInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  /* @media ${device.mobileS} {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  } */
`;

export const CounterInputWrapper = styled.div`
  border-bottom: 1px solid var(--lightGrey);
  .field {
    width: auto;
    justify-content: space-around;
    align-items: center;
  }
`;
