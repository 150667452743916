// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-enquire-js": () => import("./../../../src/pages/enquire.js" /* webpackChunkName: "component---src-pages-enquire-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listings-js": () => import("./../../../src/pages/listings.js" /* webpackChunkName: "component---src-pages-listings-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-resort-js": () => import("./../../../src/templates/resort.js" /* webpackChunkName: "component---src-templates-resort-js" */),
  "component---src-templates-villa-js": () => import("./../../../src/templates/villa.js" /* webpackChunkName: "component---src-templates-villa-js" */)
}

