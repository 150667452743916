import React, { useEffect } from "react";
import { Button } from "../../Button";
import PhoneIcon from "../../../assets/icons/phone.svg";
import Category from "../../../assets/icons/category.svg";
import { StyledWrapper, NavItem, SvgWrapper } from "./elements";
import { usePricingContext } from "../../Providers";
import {
  PRICING_MODE,
  SHOW_MAKE_A_CALL,
  SHOW_PRICING_MODAL,
} from "../../../constants";
import { useIsMobile } from "../../../hooks";

export const StickyNavBar = ({ pricingMode, defaultValues }) => {
  const {
    dispatch,
    pricingState: { showStickyNav },
  } = usePricingContext();

  const OnShowPricingModal = () => {
    dispatch({ type: SHOW_PRICING_MODAL });
  };

  const OnShowMakeACallModal = () => {
    dispatch({ type: SHOW_MAKE_A_CALL });
  };

  const isMobile = useIsMobile();
  useEffect(() => {
    if (pricingMode) dispatch({ type: PRICING_MODE, payload: { pricingMode } });
  }, [pricingMode]);

  useEffect(() => {
    if (defaultValues) {
      dispatch({ type: pricingMode, payload: { ...defaultValues } });
    }
  }, [defaultValues?.rooms?.length]);

  if (!showStickyNav) return null;
  return (
    <React.Fragment>
      <StyledWrapper>
        {isMobile ? (
          <Button
            className="qoute-btn-mobile"
            varaint="lg"
            bgColor="var(--darkPrimary)"
            onClick={OnShowPricingModal}
          >
            Request a qoute
          </Button>
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <NavItem onClick={OnShowMakeACallModal}>
                <SvgWrapper>
                  <PhoneIcon />
                </SvgWrapper>
                <div className="text">Request a call</div>
              </NavItem>
            </div>
            <div style={{ position: "relative" }}>
              <NavItem onClick={OnShowPricingModal}>
                <SvgWrapper>
                  <Category />
                </SvgWrapper>
                <div className="text">Request a Qoute</div>
              </NavItem>
            </div>
          </>
        )}
      </StyledWrapper>
    </React.Fragment>
  );
};
