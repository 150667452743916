import styled from "styled-components";
import { device } from "../../../styles/deviceSizes";
export const MainWrapper = styled.div`
  .d-flex {
    display: flex;
    &.justify-space-between {
      justify-content: space-between;
    }
    &.justify-content-center {
      justify-content: center;
    }
    &.align-items-center {
      align-items: center;
    }
    .w-full {
      width: 100%;
    }
    .flex-column {
      flex-direction: column;
    }
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }

  .form-control {
    width: 100%;
    label {
      font-size: 1.4rem;
      text-transform: capitalize;
      color: var(--darkGrey);
      margin-bottom: 1rem;
      font-weight: 400;
      @media ${device.onlyMobileSm} {
        font-size: 1.2rem;
      }
    }
    input,
    input[type="checkbox"],
    select {
      max-height: 3.8rem;
      min-height: 3.8rem;
      border-style: solid;
      border-width: 1px;
      border-radius: none;
    }
    .react-select {
      input {
        padding: 0.18rem !important;
        min-height: unset;
      }
    }
  }
`;
export const HeaderWrapper = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .header {
    position: relative;
    box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45),
      0px 25px 20px -20px rgba(0, 0, 0, 0.45);
    color: var(--darkGrey);
    font-weight: bold;
    border: 1px solid var(--primary);
    padding: 1rem 10rem;
    width: 30rem;
  }
`;

export const MainContentWrapper = styled.div`
  padding: 3rem 2rem 3rem 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${device.onlyMobileSm} {
    padding: 2rem 1rem;
  }
`;

export const TravelPlanWrapper = styled.div`
  .border-primary {
    border-color: var(--primary);
  }
  .individual {
    position: relative;
  }
  /* background-color: #e0e1ec; */

  //=================calendar styles start=================
  .react-datepicker {
    .react-datepicker__header {
      border: unset;
      background: transparent;
    }
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 5rem;

    .react-datepicker__month-container {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .react-datepicker__day-names {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    .react-datepicker__day-name {
      font-size: 1.2rem;
      font-weight: 600;
      height: 100%;
      width: 100%;
      margin: unset;
      color: #333333;
    }

    .month-navigation {
      .react-datepicker__current-month {
        font-size: 1.2rem;
      }
      .react-datepicker__navigation--previous {
        left: 0 !important;
        .react-datepicker__navigation-icon--previous {
          right: 0;
        }
      }
      .react-datepicker__navigation--next {
        right: 0;
        .react-datepicker__navigation-icon--next {
          left: 0;
        }
      }
    }

    .react-datepicker__month {
      margin: unset;
    }
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .react-datepicker__day {
      width: 100%;
      height: 100%;
      margin: unset;
      position: relative;
      color: #333333;
      .day-inner-content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border: 0px;
        text-decoration: none;
        background: none;

        padding: 1rem;
        @media ${device.onlyTableSm} {
          padding: 0.8rem;
        }
        @media ${device.mobileXL} {
          padding: 1rem;
        }
        @media ${device.mobileL} {
          padding: 0.6rem;
        }
        @media ${device.mobileS} {
          padding: 0.4rem;
        }
      }
      &.react-datepicker__day--disabled {
        color: var(--lightGrey);
      }
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
    .react-datepicker__day--keyboard-selected {
      background: none;
    }
    .react-datepicker__day {
      &:not(.react-datepicker__day--disabled) {
        :not(.react-datepicker__day--in-selecting-range):hover {
          background: var(--lightGrey);
        }
      }
    }
    .react-datepicker__day--in-range {
      border-radius: unset;
      background-color: var(--darkGrey) !important;
      .day-inner-content {
        box-shadow: var(--darkGrey) 0px 1px 0px 0px inset,
          var(--darkGrey) 0px -1px 0px 0px inset;
        border-radius: unset;
        color: white;
      }

      &.react-datepicker__day--range-start {
        border-radius: 4px 0px 0px 4px !important;
        .day-inner-content {
          border-radius: 4px 0px 0px 4px !important;
          box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
            var(--darkGrey) 0px 1px 0px 0px inset,
            var(--darkGrey) 1px 0px 0px 0px inset;
        }
      }
      &.react-datepicker__day--range-end {
        border-radius: 0px 4px 4px 0px !important;
        .day-inner-content {
          border-radius: 0px 4px 4px 0px !important;
          box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
            var(--darkGrey) 0px 1px 0px 0px inset,
            var(--darkGrey) -1px 0px 0px 0px inset;
        }
      }
      &.react-datepicker__day--range-start.react-datepicker__day--range-end {
        .day-inner-content {
          border-radius: unset !important;
        }
      }
    }
    .react-datepicker__day--in-selecting-range {
      border-radius: unset;
      background-color: white;

      .day-inner-content {
        color: #333333;
        opacity: 0.9;
        /* box-shadow: var(--darkGrey) 0px 1px 0px 0px inset,
          var(--darkGrey) 0px -1px 0px 0px inset; */
        border-radius: unset;
        background: var(--lightGrey);
      }

      &.react-datepicker__day--in-selecting-range-start,
      &.react-datepicker__day--selecting-range-start {
        .day-inner-content {
          border-radius: 4px 0px 0px 4px;
          box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
            var(--darkGrey) 0px 1px 0px 0px inset,
            var(--darkGrey) 1px 0px 0px 0px inset;
          background-color: var(--darkGrey);
          color: white;
        }
      }
      &.react-datepicker__day--in-selecting-range-end,
      &.react-datepicker__day--selecting-range-end {
        .day-inner-content {
          border-radius: 0px 4px 4px 0px;
          box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
            var(--darkGrey) 0px 1px 0px 0px inset,
            var(--darkGrey) -1px 0px 0px 0px inset;
          background-color: var(--darkGrey);
        }
      }
      &.react-datepicker__day--range-end,
      &.react-datepicker__day--range-start {
        .day-inner-content {
          box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
            var(--darkGrey) 0px 1px 0px 0px inset;
          border-radius: unset !important;
          background-color: var(--grey73);
        }
      }
    }
    .react-datepicker__day--in-selecting-range:hover {
      .day-inner-content {
        border-radius: 0px 4px 4px 0px;
        box-shadow: var(--darkGrey) 0px -1px 0px 0px inset,
          var(--darkGrey) 0px 1px 0px 0px inset,
          var(--darkGrey) 1px 0px 0px 0px inset;
        background-color: var(--darkGrey);
        color: white;
      }
    }
  }

  /* .tooltip {
    position: relative;
  } */

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: var(--darkGrey);
    color: #fff;
    text-align: center;
    border-radius: 0.6rem;
    padding: 0.5rem 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 0;
    margin-left: -2.3rem;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: var(--darkGrey) transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  //=========== calendar styles end===========

  .date {
    /* display:"flex"; */
    flex-direction: column;

    .text {
      margin-top: 1rem;
      font-size: 1.4rem;
      padding-bottom: 0.5rem;
      align-items: center;
      width: auto;
      border-bottom: 1px solid var(--lightGrey);
      @media ${device.onlyMobileSm} {
        font-size: 1.2rem;
      }
    }
  }
  .addroom-button {
    @media ${device.onlyMobileSm} {
      font-size: 1.4rem;
    }
  }
`;

export const PersonalInfoWrapper = styled.div`
  label {
    text-transform: capitalize;
  }

  input,
  textarea {
    border: 1px solid var(--lightGrey);
  }
  .form-control {
    width: 100%;
  }
  .react-tel-input {
    input {
      border-color: var(--lightGrey);
      min-height: 4rem;
      max-height: 4rem;
      border-radius: unset;
      width: 100%;
    }
  }
`;

export const SvgWrapper = styled.div`
  width: 2rem;
  cursor: pointer;
  display: flex;
  svg {
    path {
      stroke: ${({ fill, stroke }) =>
        stroke ? stroke : fill ? fill : "black"};
      fill: ${({ fill }) => (fill ? fill : "black")};
    }
    stroke: ${({ stroke }) => (stroke ? stroke : "black")};
    stroke-width: ${({ fill, strokeWidth }) =>
      fill ? (strokeWidth ? strokeWidth : "unset") : "1rem"};
    width: ${({ dimension }) => (dimension ? dimension : "1.5rem")};
    height: ${({ dimension }) => (dimension ? dimension : "1.5rem")};

    @media ${device.onlyMobileSm} {
      width: ${({ mobileDimension }) =>
        mobileDimension ? mobileDimension : "1.2rem"};
      height: ${({ mobileDimension }) =>
        mobileDimension ? mobileDimension : "1.2rem"};
    }
  }

  @media ${device.onlyMobileSm} {
    width: ${({ mobileDimension }) =>
      mobileDimension ? mobileDimension : "1.2rem"};
    height: ${({ mobileDimension }) =>
      mobileDimension ? mobileDimension : "1.2rem"};
  }
`;
