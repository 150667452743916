import React from "react";
import { Link } from "gatsby";
import { Overlay } from "../Overlay";
import { priceFormatter, getResortUrl, getVillaUrl } from "../../lib/helpers";
import { GatsbySanityImage } from "../GatsbySanityImage";

export const CollectionFeaturedContent = React.memo(
  ({ content: content_, isResort }) => {
    const content = content_.filter((content) => content !== null);
    if (!content?.length) return null;
    const firstElement = content?.[0];
    const secondElement = content?.[1];
    const villa = firstElement?.villaone;
    const featuredLogo = !isResort
      ? villa?.resort.resortBrandLogo
      : firstElement?.resortBrandLogo;

    const featuredImage = !isResort ? villa?.imageThumb : firstElement?.image;
    // console.log("featuredImage", featuredImage);
    const secondFeaturedImage = isResort
      ? secondElement?.image
      : villa?.headerImages?.images[1];
    const url = !isResort
      ? villa
        ? getVillaUrl({
            name: villa?.name,
            resortName: villa?.resort?.shortName,
          })
        : ""
      : firstElement
      ? getResortUrl({ shortName: firstElement?.shortName })
      : "";
    const viewButtonText = isResort ? "View Resort" : "View Room";
    const title = firstElement?.title;

    return (
      <div className="featured_villa_section">
        <div className="photofeatured">
          <Overlay className="overlay" />
          <div className="inner">
            {featuredLogo?.asset && (
              <GatsbySanityImage
                gatsbyImage={featuredLogo?.asset?.gatsbyImageData}
                className="featuredreslogo"
                alt={featuredLogo.alt}
              />
            )}
            {featuredImage && (
              <GatsbySanityImage
                className="featured_villa_image"
                gatsbyImage={featuredImage?.asset?.gatsbyImageData}
              />
            )}

            <div className="featuredVillaFooter">
              <Link to={url}>
                <h4 className="featuredVillaName">
                  {isResort ? firstElement?.name : villa?.name}
                </h4>
              </Link>
              {!isResort && (
                <h4 className="featuredVillaPrice">
                  {`${priceFormatter.format(villa?.price)}`}
                </h4>
              )}
              <Link to={url}>
                <h4 className="featuredVillaView">{viewButtonText}</h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="rightfeatured">
          <Overlay className="overlay" />
          <div className="inner">
            {secondFeaturedImage && (
              <GatsbySanityImage
                gatsbyImage={secondFeaturedImage?.asset?.gatsbyImageData}
                // {...secondFeaturedImage}
                alt={secondFeaturedImage.alt}
              />
            )}
            <div className={`${isResort ? "resort" : ""} txtwrap`}>
              <h2>{title || secondElement?.name}</h2>
              {!isResort ? (
                <>
                  <h3 className="tagline">{villa?.tagline}</h3>
                  <p className="description">{villa?.short_desc}</p>
                </>
              ) : (
                <Link to={url} className="viewResort">
                  <h4>{viewButtonText}</h4>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
