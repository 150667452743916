import { useLayoutEffect, useState, useRef } from "react";
import { isSSR } from "../lib/helpers";
import { SCROLL_DOWN, SCROLL_UP } from "../constants";

export const useScoll = ({
  scrollHeightToHide = 80,
  scrollHeightToShow = 0,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [pageYOffset, setPageYOffset] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  const [scrollDir, setScrollDir] = useState("scrolling down");

  const ticking = useRef(false);
  const lastScrollY = useRef(!isSSR ? window.pageYOffset : 0);

  useLayoutEffect(() => {
    const updateScrollDir = () => {
      const threshold = 0;
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY.current) < threshold) {
        ticking.current = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY.current ? SCROLL_DOWN : SCROLL_UP);
      lastScrollY.current = scrollY > 0 ? scrollY : 0;
      ticking.current = false;
    };

    const listenToScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(updateScrollDir);
        ticking.current = true;
      }
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (winScroll > scrollHeightToHide) {
        setIsVisible(false);
      }
      if (winScroll <= scrollHeightToShow) {
        setIsVisible(true);
      }
      setScrollPosition(winScroll);
      setScrollX(window.screenX);
      setPageYOffset(window.pageYOffset);
      setInnerHeight(window.innerHeight);
    };

    const scrollEvent = window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);
  return [
    isVisible,
    scrollPosition,
    scrollDir,
    scrollX,
    pageYOffset,
    innerHeight,
  ];
};
