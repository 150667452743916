import React from "react";
import Measure from "../../assets/icons/villaSpecifications/measure.svg";
import TwoPeople from "../../assets/icons/villaSpecifications/two-people.svg";
import Bed from "../../assets/icons/villaSpecifications/bed.svg";
import Shower from "../../assets/icons/villaSpecifications/shower.svg";
import SwimmingPool from "../../assets/icons/villaSpecifications/swimming-pool.svg";
import { computeVillaFields } from "../../lib/helpers";

export const VillaIcons = ({
  villa,
  wrapListContent,
  wrapperClassName,
  labelClassName = "icon-label",
  hideIcons = [],
}) => {
  const {
    sizeSqm,
    villMaxOccupancy,
    numofRooms,
    villaShowers,
    villaPoolTypes,
  } = computeVillaFields({
    villa,
  });

  return (
    <ul className={wrapperClassName}>
      <li>
        <ListContent wrapContent={wrapListContent}>
          <Measure className="villa_icon measureicon icon-label" />
          <div className={labelClassName}>{sizeSqm}m2</div>
        </ListContent>
      </li>
      <li>
        <ListContent wrapContent={wrapListContent}>
          <TwoPeople />
          <div className={labelClassName}>{villMaxOccupancy} </div>
        </ListContent>
      </li>
      <li>
        <ListContent wrapContent={wrapListContent}>
          <Bed />
          <div className={labelClassName}>{numofRooms}</div>
        </ListContent>
      </li>
      {!hideIcons.includes("shower") ? (
        <li>
          <ListContent wrapContent={wrapListContent}>
            <Shower />
            <div className={labelClassName}>{villaShowers}</div>
          </ListContent>
        </li>
      ) : null}
      {villaPoolTypes?.[0] && (
        <li>
          <ListContent wrapContent={wrapListContent}>
            <SwimmingPool />
            <div className={labelClassName}>{villaPoolTypes[0]?.poolType}</div>
          </ListContent>
        </li>
      )}
    </ul>
  );
};

const ListContent = ({ wrapContent, children }) => {
  return wrapContent ? (
    <div className="inner-content">{children}</div>
  ) : (
    children
  );
};
