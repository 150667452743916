import styled from "styled-components";
import { device } from "../styles/deviceSizes";
export const SectionContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  max-width: 110rem;
  margin: 0 auto;
  &.flex-flow-row {
    flex-flow: row;
    @media ${device.onlyMobileSm} {
      flex-flow: column;
    }
  }
  .w-100 {
    width: 100%;
  }
  @media ${device.onlyTabletAndLaptopSm} {
    padding: 10rem;
  }
  @media ${device.onlyMobileSm} {
    padding: 1.5rem;
  }
`;
