import React from "react";
import ChevronRightIcon from "../../assets/icons/chevron-right-circle.svg";
import ChevronLeftIcon from "../../assets/icons/chevron-left-circle.svg";
import { StyledButton } from "./elements";
import Slider from "react-slick";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <StyledButton
      className={className}
      style={style}
      type="button"
      onClick={onClick}
    >
      <ChevronRightIcon />
    </StyledButton>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <StyledButton
      className={className}
      style={style}
      type="button"
      onClick={onClick}
    >
      <ChevronLeftIcon />
    </StyledButton>
  );
};

const settings = {
  dots: true,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  customArrows: true,
  lazyLoad: true,
  easing: "ease",
  centerMode: true,
  speed: 500,
  swipe: true,
  swipeToSlide: true,
  useTransform: true,
  useCSS: true,
  focusOnSelect: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  appendDots: (dots) => (
    <div
      style={{
        padding: "10px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: (i) => (
    <button className="dot">
      <div>.</div>
    </button>
  ),
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        infinite: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        infinite: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
        slidesToScroll: 1,
        swipe: false,
      },
    },
  ],
};

export const Carousel = ({ children, innerRef, ...props }) => {
  const settings_ = {
    ...settings,
    ...props,
  };
  return (
    <Slider ref={innerRef} {...settings_}>
      {children}
    </Slider>
  );
};
