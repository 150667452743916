import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
import { SMALL_BANNER, MEDIUM_BANNER, LARGE_BANNER } from "../../constants";

const getHeight = (variant) => {
  switch (variant) {
    case SMALL_BANNER:
      return "35rem";
    case MEDIUM_BANNER:
      return "45rem";
    case LARGE_BANNER:
      return "65rem";
    default:
      return "100%";
  }
};

export const BannerContent = styled.div`
  position: relative;
  /* margin-top: 40px; */
  width: 100%;
  .gatsby-image-wrapper {
    height: ${({ variant }) => getHeight(variant)};

    @media ${device.onlyMobileSm} {
      height: 25rem;
    }
  }
  height: ${({ variant }) => getHeight(variant)};

  @media ${device.onlyMobileSm} {
    height: 25rem;
  }
`;

export const BannerInnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10% 0 10%;
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  font-family: vendor !important;
  font-weight: 400;
  h3 {
    font-family: vendor !important;
    letter-spacing: 3.5rem;
    color: #fff;
    z-index: 20 !important;
    @media ${device.onlyMobileS} {
      font-size: 20px;
      letter-spacing: 10px;
    }
  }
  h2 {
    text-align: center;
    font-family: vendor !important;
    letter-spacing: 3rem;
    color: #fff;
    width: 100%;
    z-index: 20 !important;
    @media ${device.onlyMobileS} {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
  .top-border {
    margin-bottom: 40px;
  }
  .bottom-border {
    margin-top: 40px;
  }

  .top-border,
  .bottom-border {
    border-bottom: 2.5px solid;
    max-width: 100%;
    margin-left: 20%;
    margin-right: 20%;
  }

  img {
    filter: brightness(0) invert(1);
  }

  .title {
    border-bottom: 1px solid white;
    text-align: center;
  }
  .sub-title {
    text-align: center;
    @media ${device.mobileXL} {
      display: none;
    }
  }
`;

export const BannerIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    height: 50rem;
    width: 50rem;
    @media ${device.onlyMobileSm} {
      width: 20rem;
      height: 20rem;
    }
  }
  .gatsby-image-wrapper {
    height: 100%;
    img {
      height: 50rem;
      width: 50rem;
      @media ${device.onlyMobileSm} {
        width: 20rem;
        height: 20rem;
      }
    }
  }
`;
