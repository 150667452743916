import React, { useEffect } from "react";
import CloseSvg from "../../assets/icons/close.svg";
import {
  StyledModel,
  ModalContent,
  SvgWrapper,
  ModalHeader,
  ModalBody,
  ModalDialog,
} from "./elements";

export const Modal = ({
  // children,
  headerText,
  handleClose,
  isOpen,
  // alignContentCenter,
  // contentStyle,
  renderBodyContent,
  isStickyHeader,
  variant,
}) => {
  useEffect(() => {
    const body = document.querySelector("body");
    if (isOpen) {
      body.classList.add("hide-scroll");
    } else {
      body.classList.remove("hide-scroll");
    }
    return () => {
      body.classList.remove("hide-scroll");
    };
  }, [isOpen]);

  // if (!isOpen) return null;
  return (
    <StyledModel isOpen={isOpen} onClick={handleClose}>
      <ModalDialog isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          <ModalHeader isStickyHeader={isStickyHeader}>
            <h4>{headerText}</h4>
            <SvgWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              <CloseSvg />
            </SvgWrapper>
          </ModalHeader>
          <ModalBody variant={variant}>{renderBodyContent?.()}</ModalBody>
        </ModalContent>
      </ModalDialog>
    </StyledModel>
  );
};
