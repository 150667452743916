import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
export const ListWrapper = styled.ol`
  list-style: none;
  text-align: center;
  width: 100%;
  /* align-items: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
  p {
    display: flex;
    font-size: 1.4rem;
    padding-top: 1rem;
    color: #3d3d3d;
  }
  .links {
    background: var(---primary);
    font-size: 1.6rem !important;
    text-transform: capitalize;
    font-weight: 700 !important;
    color: #3d3d3d;
    padding: 0.7em 1.5em;
    z-index: 2;
    width: 100%;
    @media ${device.onlyMobileSm} {
      font-size: 1.4rem !important;
    }
  }
`;

export const ListItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  :not(.active) {
    .links {
      border-color: var(---primary);
      color: #e0e1ec;
    }
    p {
      color: #e0e1ec;
    }
    &::after {
      background: #fef5f6;
    }
  }

  @media ${device.onlyMobileSm} {
    .links {
      padding: 0.5rem;
    }
  }
`;
export const ProgressLine = styled.div`
  width: 100%;
  background: ${({ isActive }) => (isActive ? "var(--darkGrey)" : "#e0e1ec")};
  height: 0.4rem;
`;
