import styled from "styled-components";
import { device } from "../../../styles/deviceSizes";
export const StyledWrapper = styled.div`
  position: fixed;
  z-index: 2;
  /* width: 100%; */
  top: calc(100% - (50% + 6rem));
  right: 0;

  transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
  display: flex;
  column-gap: 1rem;
  row-gap: 7rem;
  flex-direction: column;
  justify-content: center;
  font-size: 1.4rem;

  @media ${device.onlyMobileSm} {
    bottom: 1rem;
    right: unset;
    top: unset;
    align-items: center;
    width: 100%;
  }

  .qoute-btn-mobile {
    border-radius: 2rem 2rem 2rem 2rem;
    color: white;
    margin: 0 1rem;
    width: 80%;
    backdrop-filter: blur(20px);
    background-color: rgba(21, 21, 21, 0.3);
    transform: matrix(1, 0, 0, 1, 0, 0);
    font-size: 1.4rem;
  }
`;

export const NavItem = styled.div`
  /* background: var(--darkPrimary); */
  display: flex;
  position: absolute;
  right: -16.5rem;
  align-items: center;
  color: white;
  line-height: 2rem;
  width: 20rem;
  padding: 1.6rem;
  text-align: center;
  text-transform: uppercase;
  transition: background 1s ease-in-out, right 1s ease-in-out;
  backdrop-filter: blur(20px);
  background: rgba(21, 21, 21, 0.3);
  transform: matrix(1, 0, 0, 1, 0, 0);
  &:hover {
    background: rgba(21, 21, 21, 0.3);
    right: 0;
  }
  cursor: pointer;
  .text {
    display: flex;
    /* writing-mode: tb-rl;
    transform: rotate(-180deg); */
    word-spacing: 0.5rem;
  }
`;

export const SvgWrapper = styled.div`
  margin-right: 1rem;
  svg {
    fill: ${({ fill }) => (fill ? fill : "white")};
    stroke: ${({ stroke }) => (stroke ? stroke : "white")};
    stroke-width: ${({ strokeWidth }) => strokeWidth || "0.2rem"};
    width: ${({ dimension }) => (dimension ? dimension : "1.2rem")};
    height: ${({ dimension }) => (dimension ? dimension : "1.2rem")};
  }
`;
