import React, { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";
import {
  TRAVEL_PLAN,
  ALL_RESORTS,
  PERSONAL_INFO,
  PRICING_MODE,
  RESORT_MODE,
  HIDE_STICKY_NAV,
  SHOW_STICKY_NAV,
  SHOW_PRICING_MODAL,
  HIDE_PRICING_MODAL,
  VILLA_MODE,
  GLOBAL,
  SHOW_MAKE_A_CALL,
  HIDE_MAKE_A_CALL,
  SHOW_CONTACT_US,
  HIDE_CONTACT_US,
} from "../../constants";
import { useQuery } from "@apollo/client";
import { Query_GET_ALLRESORTS } from "../../gql";
import { useNavBar, useScoll } from "../../hooks";
import { ContactUsModal, PricingModal } from "../Modal";

export const PricingContext = createContext({});

const initialState = {
  travelPlan: {
    rooms: [
      {
        room: undefined,
        dateOfTravel: [],
        adults: 2,
        childrens: 0,
        childrensAge: [],
      },
    ],
    resort: null,
  },
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    countryOfResidence: "",
    budget: "",
    comments: "",
  },
  allResorts: [],
  allRooms: [],
  pricingMode: GLOBAL,
  showStickyNav: true,
  showPricingModal: false,
  showMakeCallModal: false,
  showContactUs: false,
};

const defaultModesState = (state, payload) => {
  const { defaultRoomId, defaultResortId, rooms } = payload;
  let defaultRoom = undefined;
  let defaultResort = undefined;
  const allRooms = [...rooms];
  if (defaultRoomId)
    defaultRoom = allRooms?.find(({ _id }) => _id === defaultRoomId);
  if (defaultResortId && state?.allResorts?.length)
    defaultResort = state?.allResorts.find(
      ({ _id }) => defaultResortId === _id
    );

  const travelPlanRooms = [...state?.travelPlan?.rooms]?.map((room) => {
    return {
      ...room,
      ...(defaultRoom
        ? { room: defaultRoom }
        : defaultResort
        ? { resort: defaultResort, room: undefined }
        : {}),
    };
  });

  return {
    ...state,
    allRooms,
    ...{
      travelPlan: { ...state?.travelPlan, rooms: [...travelPlanRooms] },
    },
  };
};

const pricingReducer = (state, { type, payload }) => {
  switch (type) {
    case TRAVEL_PLAN: {
      return { ...state, travelPlan: { ...payload } };
    }
    case PERSONAL_INFO:
      return { ...state, personalInfo: { ...payload } };
    case ALL_RESORTS:
      return { ...state, allResorts: [...payload?.resorts] };
    case PRICING_MODE:
      return { ...state, pricingMode: payload.pricingMode };
    case RESORT_MODE:
      return defaultModesState(state, payload);
    case VILLA_MODE:
      return defaultModesState(state, payload);
    case HIDE_STICKY_NAV:
      return { ...state, showStickyNav: false };
    case SHOW_STICKY_NAV:
      return { ...state, showStickyNav: true };
    case SHOW_PRICING_MODAL:
      return { ...state, showPricingModal: true };
    case HIDE_PRICING_MODAL:
      return { ...state, showPricingModal: false };
    case SHOW_MAKE_A_CALL:
      return { ...state, showMakeCallModal: true };
    case HIDE_MAKE_A_CALL:
      return { ...state, showMakeCallModal: false };
    case SHOW_CONTACT_US:
      return { ...state, showContactUs: true };
    case HIDE_CONTACT_US:
      return { ...state, showContactUs: false };
    default:
      return state;
  }
};

export const PricingProvider = ({ children }) => {
  const [pricingState, dispatch] = useReducer(pricingReducer, initialState);
  const value = { pricingState, dispatch };
  const { data: allResorts_ } = useQuery(Query_GET_ALLRESORTS, {
    variables: { isPricingMode: true },
  });

  const allResorts = allResorts_?.allResort;
  //@TODO move this logic to navbar to prevent extra re-rendering
  const { heroRef } = useNavBar();
  const maxScroll = heroRef?.current?.clientHeight;
  const [_, scrollPosition] = useScoll({
    scrollHeightToHide: undefined,
    scrollHeightToShow: undefined,
  });
  useEffect(() => {
    if (maxScroll && scrollPosition < maxScroll - maxScroll / 2) {
      dispatch({ type: HIDE_STICKY_NAV });
    } else {
      if (!pricingState.showStickyNav) dispatch({ type: SHOW_STICKY_NAV });
    }
  }, [maxScroll, scrollPosition]);

  useEffect(() => {
    if (allResorts?.length) {
      dispatch({
        type: ALL_RESORTS,
        payload: { resorts: allResorts },
      });
    }
  }, [allResorts?.length]);

  return (
    <PricingContext.Provider value={value}>
      {children}
      <PricingModal
        isOpen={pricingState?.showPricingModal}
        pricingMode={pricingState?.pricingMode}
        handleClose={() => {
          dispatch({ type: HIDE_PRICING_MODAL });
        }}
      />
      <ContactUsModal
        isOpen={pricingState?.showMakeCallModal || pricingState?.showContactUs}
        isMakeCall={pricingState?.showMakeCallModal}
        handleClose={() => {
          if (pricingState?.showMakeCallModal)
            dispatch({ type: HIDE_MAKE_A_CALL });
          if (pricingState?.showContactUs) dispatch({ type: HIDE_CONTACT_US });
        }}
      />
    </PricingContext.Provider>
  );
};

export const usePricingContext = () => useContext(PricingContext);
