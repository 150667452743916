export const LIGHT_COLOR = "LIGHT";
export const LIGHT_BG = "light background";
export const DARK_BG = "dark background";
export const ROOM_PAGE = "room-page";
// export const HOME_PAGE = ""
export const ACCOMODATIONS_SECTION = "Accomodation";
export const ACCOMODATION = "Accomodation";
export const OVERVIEW = "overview";
export const SPA = "spa";
export const ACTIVITIES = "activities";
export const HIGhLIGHTS = "highlights";
export const DINE = "dine";
export const FEATURES = "features";
export const ISLAND = "island";
export const RESORTS = "Resorts";
export const VILLAS = "villas";
export const RESORT = "Resorts";
export const VILLA = "villas";
export const COLLECTIONS = "Collections";
export const HOME = "Home";
export const MAGAZINE = "Magazine";
export const DEFAULT_NAVBAR_WITH_BOTTOM_LINK =
  "default-navbar-with-bottom-links";
export const SIMPLE_MAIN_NAVBAR = "simple-main-navbar";
export const WATER_VILLA_COLLECTION = "Water Villas";
export const BEACH_VILLA_COLLECTION = "Beach Villas";
export const RESORT_COLLECTION = "Resort Collection";
export const COUPLES_COLLECTION = "Couples Collection";
export const LARGE_COLLECTION = "Large Villas";

// Banners CONSTANTS

export const SMALL_BANNER = "sm";
export const FULL_HEIGHT_BANNER = "full-height";
export const MEDIUM_BANNER = "md";
export const LARGE_BANNER = "lg";

// SCROL

export const SCROLL_UP = "scroll up";
export const SCROLL_DOWN = "scroll down";

// PRICING MODAL

export const TRAVEL_PLAN = "Travel Plan";
export const PERSONAL_INFO = "Personal Info";
export const ALL_RESORTS = "All Resorts";
export const GLOBAL = "global";
export const RESORT_MODE = "resort mode";
export const VILLA_MODE = "villa mode";
export const PRICING_MODE = "Pricing Mode";
export const DEFAULT_ROOMS = "default rooms";
export const HIDE_STICKY_NAV = "hide sticky navbar";
export const SHOW_STICKY_NAV = "show sticky navbar";
export const HIDE_PRICING_MODAL = "hide pricing modal";
export const SHOW_PRICING_MODAL = "show pricing modal";
export const HIDE_MAKE_A_CALL = "hide make a call";
export const SHOW_MAKE_A_CALL = "show make a call";
export const SHOW_CONTACT_US = "show contact us";
export const HIDE_CONTACT_US = "hide contact us";
export const DEFAULT_RESORT = "default resort id";
