import React from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField } from "../../Input";
import { PersonalInfoWrapper } from "./elements";
import PhoneInput from "../../PhoneInput/PhoneInput";
import { ControlledReactSelect } from "./ControlledReactSelect";
import { Button } from "../../Button";
import { usePricingContext } from "../../Providers";
import { PERSONAL_INFO } from "../../../constants";
import countriesData from "../../../lib/countries";

const validationSchema = yup
  .object({
    name: yup.string().required("Required*"),
    email: yup.string().email("Email is not valid").required("Required*"),
    phoneNumber: yup.string().required("Required*"),
    budget: yup.string().required("Required*"),
  })
  .required();

const budgetRanges = [
  { label: "$3,500 - $5,000", value: "$3,500 - $5,000" },
  { label: "$5,000 - $7,500", value: "$5,000 - $7,500" },
  { label: "$7,500 - $10,000", value: "$7,500 - $10,000" },
  { label: "$10,000 - 15,000", value: "$10,000 - 15,000" },
  { label: "$15,000 - $20,000", value: "$15,000 - $20,000" },
  { label: "$20,000 - $25,000", value: "$20,000 - $25,500" },
  { label: "$25,000 - $30,000", value: "$25,000 - $30,000" },
  { label: "$30,000 - $35,000", value: "$30,000 - $35,000" },
  { label: "Above $35,000", value: "Above $35,000" },
];

const buttonStyle = {
  margin: "1rem 0 0 0",
  color: "var(--doveGrey)",
  borderColor: "var(--lightGrey)",
};

export const PersonalInfo = ({ onPreviousClick }) => {
  const {
    pricingState: { personalInfo },
    dispatch,
  } = usePricingContext();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: personalInfo,
    resolver: yupResolver(validationSchema),
  });
  const allFields = useWatch({ control });
  const email = watch("email");
  const saveData = (data) => {
    dispatch({ type: PERSONAL_INFO, payload: data });
  };
  const allCountries = countriesData?.map(({ code, name }) => ({
    label: name,
    value: code,
  }));
  return (
    <PersonalInfoWrapper>
      <form onSubmit={handleSubmit(saveData)}>
        <TextField
          labelText="Name"
          placeholder="Enter name"
          wrapperClassName="form-control"
          {...register("name")}
        />
        <TextField
          labelText="Email"
          {...register("email")}
          wrapperClassName="form-control"
          placeholder="Enter email"
        />
        <div className="form-control mb-2">
          <label>Phone Number</label>

          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                className="mt-1"
                country="gb"
                value={value}
                onChange={(value) => {
                  onChange(value);
                }}
                specialLabel=""
              />
            )}
          />
          {/* <ErrorField error={phoneNumber} /> */}
        </div>
        <div className="form-control mb-2">
          <label>Country of residence</label>
          <ControlledReactSelect
            fieldName="countryOfResidence"
            className="mt-1 react-select"
            placeholder="Select country of residence..."
            control={control}
            options={allCountries}
          />
        </div>

        <div className="form-control mb-2">
          <label>Budget</label>
          <ControlledReactSelect
            fieldName="budget"
            className="mt-1 react-select"
            placeholder="Select the budget..."
            control={control}
            options={budgetRanges}
          />
        </div>

        <TextField
          labelText="Tell us a bit more"
          variant="textarea"
          wrapperClassName="form-control mb-2"
          {...register("comments")}
        />

        <div
          style={{
            marginTop: "2rem",
            marginBottom: "4rem",
            justifyContent: "space-between",
          }}
          className="d-flex"
        >
          <Button
            variant="sm"
            styles={buttonStyle}
            hoverColor="var(--black)"
            onClick={(e) => {
              e.preventDefault();
              dispatch({ type: PERSONAL_INFO, payload: allFields });
              onPreviousClick?.();
            }}
          >
            Prev
          </Button>
          <Button
            variant="sm"
            disabled={errors?.email}
            hoverColor="var(--black)"
            styles={buttonStyle}
          >
            Submit
          </Button>
        </div>
      </form>
    </PersonalInfoWrapper>
  );
};
