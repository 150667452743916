import React from "react";
import { navigate } from "gatsby";
import { VillaIcons } from "../Villa/VillaIcons";
import { Button, GatsbySanityImage, PriceTemplate } from "../../components";
import { truncate, computeVillaFields, getResortUrl } from "../../lib/helpers";
import Placeholder from "../../assets/placeholder.svg";

export const CollectionCard = ({
  content,
  listKey,
  navigationState,
  wrapperClassName,
  isResort,
}) => {
  if (!content) return null;
  const villa = !isResort
    ? computeVillaFields({ villa: { ...content } })
    : undefined;
  const resortBrandLogo = !isResort
    ? content?.resort?.resortBrandLogo
    : content?.resortBrandLogo;
  const url = !isResort
    ? villa?.villaUrl
    : getResortUrl({ shortName: content?.shortName });
  const collectionImage = !isResort ? content?.imageThumb : content?.image;
  const viewButtonText = !isResort ? "View Room" : "View Resort";
  return (
    <li
      key={listKey}
      className={wrapperClassName}
      onClick={() => {
        navigate(url, {
          state: navigationState,
        });
      }}
    >
      <div className="collection__image">
        {collectionImage ? (
          <GatsbySanityImage
            gatsbyImage={collectionImage?.asset?.gatsbyImageData}
          />
        ) : (
          <Placeholder
            style={{ height: "auto", width: "100%" }}
            className="gatsby-image-wrapper"
          />
        )}
      </div>
      <div className="collection__details">
        <h4 className="villaname">{truncate(content.name, 40)}</h4>

        {!isResort ? (
          <>
            <VillaIcons
              villa={content}
              wrapperClassName="icons"
              labelClassName="icon_label"
            />
            <PriceTemplate
              priceOnRequest={content?.priceOnRequest}
              className="villa_price"
              price={villa?.villaPrice}
            />
          </>
        ) : (
          <ResortCollectionIcons content={content} />
        )}

        <div className="logo-wrapper">
          <div className="view-room-btn">
            <Button
              onClick={() => {
                navigate(url, {
                  state: navigationState,
                });
              }}
            >
              {viewButtonText}
            </Button>
          </div>
          {resortBrandLogo && (
            <div className="collection_brand_logo">
              {resortBrandLogo && resortBrandLogo.asset && (
                <GatsbySanityImage
                  mainWrapperStyle={{ width: "auto" }}
                  gatsbyImage={resortBrandLogo?.asset?.gatsbyImageData}
                  alt={resortBrandLogo.alt}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export const CollectionCardMobileView = ({
  content,
  navigationState,
  listKey,
  isResort,
}) => {
  if (!content) return null;
  const villa = !isResort
    ? computeVillaFields({ villa: { ...content } })
    : undefined;
  const url = !isResort
    ? villa?.villaUrl
    : getResortUrl({ shortName: content?.shortName });
  const collectionImage = !isResort ? content?.imageThumb : content?.image;
  const viewButtonText = !isResort ? "View Room" : "View Resort";
  return (
    <>
      <li key={listKey} className="collection__image">
        {collectionImage && collectionImage?.asset ? (
          <GatsbySanityImage
            gatsbyImage={collectionImage?.asset?.gatsbyImageData}
            alt={collectionImage.alt}
          />
        ) : (
          <Placeholder
            style={{ height: "auto", width: "100%" }}
            className="gatsby-image-wrapper"
          />
        )}
      </li>
      <div className="collection__details">
        <h4 className="roomname">{truncate(content.name, 40)}</h4>
        {!isResort ? (
          <>
            <VillaIcons
              villa={content}
              wrapperClassName="icons"
              labelClassName="icon_label"
            />
            <PriceTemplate
              className="price-template"
              price={villa?.villaPrice}
              styles={{ marginTop: "2rem" }}
            />
          </>
        ) : (
          <ResortCollectionIcons content={content} />
        )}
        <div className="view-room-btn">
          <Button
            onClick={() => {
              navigate(url, {
                state: navigationState,
              });
            }}
          >
            {viewButtonText}
          </Button>
        </div>
      </div>
    </>
  );
};

export const ResortCollectionIcons = ({ content }) => {
  const {
    locationAtoll,
    numberOfBars,
    numberOfRestaurants,
    numberOfRooms,
    resortTransferType,
    timeToAirport,
  } = content;
  return (
    <ul className="icons resorts">
      {numberOfRooms ? (
        <li>
          <div className="icon_label">Rooms</div>
          <p className="feature_value">{numberOfRooms}</p>
        </li>
      ) : null}
      {numberOfRestaurants ? (
        <li>
          <div className="icon_label">Restaurants</div>
          <p className="feature_value">{numberOfRestaurants}</p>
        </li>
      ) : null}
      {numberOfBars ? (
        <li>
          <div className="icon_label">Bars</div>
          <p className="feature_value">{numberOfBars}</p>
        </li>
      ) : null}
      {locationAtoll ? (
        <li>
          <div className="icon_label">Location</div>
          <p className="feature_value">{locationAtoll}</p>
        </li>
      ) : null}
      {timeToAirport ? (
        <li>
          <div className="icon_label">Time to airport</div>
          <p className="feature_value">{timeToAirport}</p>
        </li>
      ) : null}
      {resortTransferType?.length ? (
        <li>
          <div className="icon_label">Transfers</div>
          <p className="feature_value">
            {resortTransferType?.map(
              (type, index) =>
                `${type.transferType}${
                  index + 1 !== resortTransferType.length ? "/" : ""
                }`
            )}
          </p>
        </li>
      ) : null}
    </ul>
  );
};
