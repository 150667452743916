import styled from "styled-components";
// import { device } from "../../styles/deviceSizes";
export const DropDownWrapper = styled.div`
  display: flex;
  color: var(--primary) !important;
  align-self: center;
  border: 1.5px solid var(--primary);
  background: none;
  cursor: pointer;
  padding: 12px 0px;
  vertical-align: middle;
  align-items: center;
  flex-direction: column;
  width: 203px;
  justify-content: center;
  transition: all 1s ease-in-out;

  :not(.noHover):hover {
    cursor: pointer;
    background: #91715c;
    color: #fff !important;
    svg {
      fill: #fff !important;
    }
  }
`;
export const DropDownHeader = styled.div`
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  padding: ${({ isOpen }) => `0px 0px ${isOpen ? "20px" : "0px"} 0px;`};
  .header-title {
    margin-top: 2px;
  }
`;

export const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0px 25px;
`;

export const ListItem = styled.div`
  padding: 0px 0px 10px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .month {
    width: 20%;
    display: flex;
    font-size: 12px;
    text-transform: capitalize;
    text-align: left;
  }
  .pricing {
    display: flex;
    align-items: center;
    .content {
      display: flex;
      flex-direction: column;
      .label {
        margin-bottom: 0.5rem;
      }
      .value {
        font-size: 1.6rem;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;

  width: 100%;
  svg {
    position: absolute;
    left: 92%;
    bottom: 10%;
  }
`;
export const SvgWrapper = styled.div`
  svg {
    fill: #b39a6a;
    stroke: #b39a6a;
    stroke-width: ${({ strokeWidth }) => strokeWidth || "2px"};
    width: ${({ dimension }) => (dimension ? dimension : "10px")};
    height: ${({ dimension }) => (dimension ? dimension : "10px")};
  }
`;

export const MainWrapper = styled.div`
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px 0px,
    rgb(0 0 0 / 10%) 0px 0.3px 0.5px 0px;
`;

export const Summary = styled.div`
  margin-bottom: 0px;
  margin-inline: 0px;
  margin-top: 0px;
  flex: 0 1 auto;
  margin-bottom: 1.6rem;
  .title {
    font-size: 1.6rem;
    line-height: 1.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    margin-bottom: 1rem;
  }
  .price {
    font-size: 2rem;
    font-family: Hind;
    font-weight: 600;
    letter-spacing: 0rem;
    line-height: 2.25rem;
  }
`;
