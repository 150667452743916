import React, { useState, useEffect } from "react";
import { Modal } from "../Modal";
import { TravelPlan } from "./TravelPlan";
import { PersonalInfo } from "./PersonalInfo";
import { Stepper } from "../../Stepper";
import { PricingProvider } from "../../Providers";
import { HeaderWrapper, MainContentWrapper, MainWrapper } from "./elements";
import { TRAVEL_PLAN, PERSONAL_INFO } from "../../../constants";

const data_ = [
  {
    stepNo: TRAVEL_PLAN,
    label: TRAVEL_PLAN,
  },
  {
    stepNo: PERSONAL_INFO,
    label: PERSONAL_INFO,
  },
];

export const PricingModal = ({ isOpen, handleClose }) => {
  const [activeItems, setActiveItems] = useState([TRAVEL_PLAN]);
  const activeStep = activeItems[activeItems?.length - 1];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const onStepClick = (itemNo) => {
    if (itemNo) {
      if (activeItems.includes(itemNo)) {
        const lastItem = data_[data_?.length - 1]?.stepNo === itemNo;
        const prevItem = data_[data_?.length - 2]
          ? data_[data_?.length - 2]?.stepNo
          : "";

        if (lastItem) {
          setActiveItems([prevItem, itemNo]);
        } else {
          setActiveItems([itemNo]);
        }
      } else {
        const activeItems_ = [...activeItems];
        activeItems_.push(itemNo);
        setActiveItems(activeItems_);
      }
    }
  };

  const onNextClick = () => {
    const activeItems_ = [...activeItems];
    activeItems_.push(PERSONAL_INFO);
    setActiveItems(activeItems_);
  };

  const onPreviousClick = () => {
    const activeItems_ = [...activeItems];
    activeItems_.pop();
    setActiveItems(activeItems_);
  };

  const renderContent = () => {
    switch (activeStep) {
      case TRAVEL_PLAN:
        return <TravelPlan onNextClick={onNextClick} />;
      case PERSONAL_INFO:
        return <PersonalInfo onPreviousClick={onPreviousClick} />;
      default:
        <TravelPlan />;
    }
  };

  // if (!isOpen) return null;
  return (
    <Modal
      isOpen={isOpen}
      headerText={activeItems[activeItems.length - 1]}
      handleClose={handleClose}
      isStickyHeader
      renderBodyContent={
        isOpen
          ? () => (
              <MainWrapper>
                <HeaderWrapper>
                  <Stepper
                    data={data_}
                    activeItems={activeItems}
                    onItemClick={onStepClick}
                  />
                </HeaderWrapper>

                <MainContentWrapper>{renderContent?.()}</MainContentWrapper>
              </MainWrapper>
            )
          : null
      }
    ></Modal>
  );
};
