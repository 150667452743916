import { gql } from "@apollo/client";
import { Fragment_MainImage, Fragment_RateModel } from "./fragments";
export const Query_Villa = gql`
  query Villa($id: ID!) {
    Villa(id: $id) {
      _id
      name
      headerImages {
        images {
          ...Fragment_MainImage
          alt
        }
      }
      numrooms
      sizeSqm
      showers {
        option
        number
      }

      villaPoolTypes {
        poolType
      }

      maxOccupancy {
        option
        number
      }
      resort {
        name
      }
    }
  }
  ${Fragment_MainImage}
`;
export const Query_GET_ALLRESORTS = gql`
  query AllResorts($isPricingMode: Boolean = false) {
    allResort(where: { active: { eq: true } }) {
      _id
      name
      image {
        asset {
          _id
        }
        alt
      }
      locationAtoll
      locationFull @skip(if: $isPricingMode)
      numberOfBars @skip(if: $isPricingMode)
      numberOfRestaurants @skip(if: $isPricingMode)
      numberOfRooms
      roomVoltage @skip(if: $isPricingMode)
      timeToAirport
      villas {
        _id
        uniqueCode
        name
        active
        priceOnRequest
        imageThumb {
          asset {
            _id
          }
          alt
        }
        resort {
          _id
          name
        }
        numrooms
        roomFeatures {
          features {
            _key
            descriptionRaw
            title
          }
        }
        sizeSqm

        villaPoolTypes {
          poolType
        }

        maxOccupancy {
          option
          number
        }
        rateModel {
          ...Fragment_RateModel
        }
      }
    }
  }
  ${Fragment_RateModel}
`;
