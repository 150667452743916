import React, { useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { sanity } from "../../client-config";
const defaultGatsbyImageConfig = {
  placeholder: "blurred",
};
export const GatsbySanityImage = ({
  assetId,
  gatsbyImage,
  mainWrapperStyle,
  imageConfig = defaultGatsbyImageConfig,
  ...props
}) => {
  const imageWrapperRef = useRef();
  React.useEffect(() => {
    const handleRightClick = (e) => {
      e.preventDefault();
    };
    const imageWrapper = imageWrapperRef?.current;
    if (imageWrapper)
      imageWrapper.addEventListener("contextmenu", handleRightClick);
    return () => {
      imageWrapper.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  const sanityConfig = sanity;

  const imageData = getGatsbyImageData(
    assetId,
    { maxWidth: 1024, ...imageConfig },
    sanityConfig
  );
  const mainWrapperStyle_ = {
    ...{ width: "100%", height: "auto" },
    ...mainWrapperStyle,
  };
  return (
    <div ref={imageWrapperRef} style={mainWrapperStyle_}>
      <GatsbyImage as="div" image={imageData || gatsbyImage} {...props} />
    </div>
  );
};
