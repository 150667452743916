import React from "react";
import { ListWrapper, ProgressLine, ListItem } from "./elements";

export const Stepper = ({ data, activeItems, onItemClick }) => {
  if (!data?.length) return null;
  return (
    <ListWrapper>
      {data?.map(({ stepNo, label }, index) => {
        const isActive = activeItems.includes(stepNo);
        const nextStep = data[index + 1]?.stepNo
          ? data[index + 1]?.stepNo
          : null;
        const isActiveNext = activeItems.includes(nextStep);

        return (
          <React.Fragment>
            <ListItem
              className={isActive ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                onItemClick(stepNo);
              }}
            >
              <div
                className="links"
                style={index === 0 ? { textAlign: "center" } : {}}
              >
                {label}
              </div>
            </ListItem>
            {index !== data?.length - 1 ? (
              <div className="d-flex" style={{ width: "100%" }}>
                <ProgressLine isActive={isActiveNext} />
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </ListWrapper>
  );
};
