import React, { useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { differenceInDays, format, isEqual } from "date-fns";
import CalendarIcon from "../../../../assets/icons/calendar.svg";
import MinusIcon from "../../../../assets/icons/minus-icon.svg";
import PlusIcon from "../../../../assets/icons/plus-icon.svg";
import { SvgWrapper } from "../elements";
import { priceFormatter } from "../../../../lib/helpers";

import { CustomDatepickerInputWrapper, CounterInputWrapper } from "./elements";
import { useRef } from "react";
import { useIsMobile } from "../../../../hooks";

const isInRange = (date, startDate, endDate) => {
  if (date >= startDate && date <= endDate) {
    return true;
  } else {
    return false;
  }
};

const CustomDatepickerInput = ({ arrivalDate, departureDate, onClick }) => {
  return (
    <CustomDatepickerInputWrapper onClick={onClick}>
      <div className="d-flex date form-control">
        <div className="d-flex">
          <label>Check in</label>
        </div>
        <div className="text d-flex">
          <SvgWrapper
            dimension="1.5rem"
            mobileDimension="1.2rem"
            style={{ marginRight: "0.3rem" }}
          >
            <CalendarIcon />
          </SvgWrapper>
          <div>{arrivalDate}</div>
        </div>
      </div>
      <div className="d-flex date form-control">
        <div className="d-flex">
          <label>Check out</label>
        </div>
        <div className="text d-flex">
          <SvgWrapper
            dimension="1.5rem"
            mobileDimension="1.2rem"
            style={{ marginRight: "0.3rem" }}
          >
            <CalendarIcon />
          </SvgWrapper>
          <div>{departureDate}</div>
        </div>
      </div>
    </CustomDatepickerInputWrapper>
  );
};

export const CounterInput = ({
  value,
  onMinusClick,
  onPlusClick,
  label,
  valueLabel,
  wrapperClassName,
}) => {
  return (
    <CounterInputWrapper className={wrapperClassName}>
      <label>{label}</label>
      <div className="d-flex mt-1 mb-1 field">
        <SvgWrapper
          onClick={onMinusClick}
          dimension="1.2rem"
          mobileDimension="1rem"
          fill="var(--darkGrey)"
          stroke="unset"
        >
          <MinusIcon />
        </SvgWrapper>
        <div className="d-flex">
          <div className="mr-1">{value}</div>
          <div>{valueLabel}</div>
        </div>
        <SvgWrapper
          onClick={onPlusClick}
          dimension="1.2rem"
          mobileDimension="1rem"
          stroke="unset"
          fill="var(--darkGrey)"
        >
          <PlusIcon />
        </SvgWrapper>
      </div>
    </CounterInputWrapper>
  );
};

export const ChildAgeFields = ({
  control,
  childrens,
  getValues,
  setValue,
  nestIndex,
}) => {
  const {
    fields: childsAgeFields,
    remove,
    append: appendChildAge,
  } = useFieldArray({
    control,
    name: `rooms.${nestIndex}.childrensAge`,
  });

  React.useEffect(() => {
    if (childrens > 0) {
      const childrensAgeLength = childsAgeFields?.length;
      if (parseInt(childrens) > childrensAgeLength) {
        appendChildAge({ age: 0 });
      } else if (parseInt(childrens) <= childrensAgeLength) {
        for (let j = childrensAgeLength; j > childrens - 1; j--) {
          remove([j]);
        }
      }
    } else {
      remove();
    }
  }, [childrens, childsAgeFields?.length]);

  return childsAgeFields?.length
    ? childsAgeFields?.map((_, childIndex) => {
        const childAge = getValues(
          `rooms.${nestIndex}.childrensAge.${childIndex}.age`
        );
        return (
          <CounterInput
            wrapperClassName="form-control mb-1"
            valueLabel=""
            label={`Child ${childIndex + 1} age`}
            value={childAge}
            onPlusClick={() => {
              setValue(
                `rooms.${nestIndex}.childrensAge.${childIndex}.age`,
                childAge + 1
              );
            }}
            onMinusClick={() => {
              if (childAge > 0)
                setValue(
                  `rooms.${nestIndex}.childrensAge.${childIndex}.age`,
                  childAge - 1
                );
            }}
          />
        );
      })
    : null;
};

export const ControlledDatePicker = ({
  dateOfTravel,
  fieldName,
  monthlyPricing,
  control,
  ...datePickerProps
}) => {
  const [activeDate, setActiveDate] = useState(null);
  const dateRange = useRef([]);
  const isMobile = useIsMobile();
  const arrivalDate =
    dateOfTravel?.length && dateOfTravel[0]
      ? format(new Date(dateOfTravel[0]), "d MMMM yyyy")
      : "";
  const departureDate =
    dateOfTravel?.length && dateOfTravel[1]
      ? format(dateOfTravel[1], "d MMMM yyyy")
      : "";

  const renderDayContents = (day, date) => {
    const isInRange_ =
      dateOfTravel?.[0] &&
      dateOfTravel?.[1] == null &&
      isInRange(new Date(date), new Date(dateOfTravel[0]), activeDate);

    let difference = isInRange_
      ? `${differenceInDays(activeDate, new Date(dateOfTravel[0]))}`
      : 0;

    const month = format(new Date(date), "LLLL").toLowerCase();
    const monthlyPrice = monthlyPricing?.[month.toLowerCase()];
    const seasonalPrice = monthlyPrice?.seasonalPrice;
    const isInSeasonalRange = isInRange(
      new Date(date),
      new Date(seasonalPrice?.startDate),
      new Date(seasonalPrice?.endDate)
    );
    const dayPrice =
      isInSeasonalRange && seasonalPrice?.price
        ? seasonalPrice?.price
        : monthlyPrice?.normalPrice?.price;

    let totalSum = 0;
    if (isInRange_) {
      let dateRange_ = [...dateRange?.current];
      const date_ = format(new Date(date), "d MMMM yyyy");

      const someDatesInRange = dateRange_?.some(({ date }) => date === date_);
      if (!dateRange_?.length) {
        dateRange_.push({ date: date_, price: dayPrice });
      } else {
        if (someDatesInRange) {
          const activeDateIndex = dateRange_?.findIndex(
            (x) => x?.date === date_
          );
          dateRange_ = [...dateRange_.slice(0, activeDateIndex + 1)];
        } else if (dateRange_?.length && !someDatesInRange) {
          dateRange_.push({ date: date_, price: dayPrice });
        }
      }
      dateRange["current"] = [...dateRange_];
      totalSum = dateRange_.reduce((acc, { price }) => {
        return (acc += parseInt(price));
      }, 0);
    }

    return (
      <>
        <div className="tooltip">
          <div
            className={`day-inner-content ${
              difference
                ? `in-range ${
                    isEqual(date, activeDate)
                      ? "react-datepicker__day--selecting-range-end"
                      : ""
                  }`
                : ``
            }`}
          >
            {difference ? (
              <span class="tooltiptext">{`${
                difference
                // dateRange?.current?.length
              } nights  ${
                totalSum > 0 ? `  - ${priceFormatter.format(totalSum)}` : ""
              }`}</span>
            ) : null}
            <span>
              <div>{day}</div>
              {/* <div className="price">${dayPrice}</div> */}
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={dateOfTravel}
      render={({ field: { onChange, value } }) => {
        return (
          <DatePicker
            withPortal={isMobile}
            fixedHeight
            dateFormat={"d MMMM yyyy"}
            selectsRange
            showPreviousMonths={false}
            monthsShown={2}
            selected={value[1]}
            startDate={value[0]}
            endDate={value[1]}
            minDate={new Date()}
            onDayMouseEnter={(date, day) => {
              setActiveDate(date);
            }}
            onChange={(date) => {
              onChange(date);
              dateRange["current"] = [];
            }}
            customInput={
              <CustomDatepickerInput
                arrivalDate={arrivalDate}
                departureDate={departureDate}
              />
            }
            renderDayContents={renderDayContents}
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div className="custom-header-wrapper">
                <div className="month-navigation">
                  <button
                    type="button"
                    aria-label="Previous Month"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--previous"
                    }
                    style={
                      customHeaderCount === 1
                        ? { visibility: "hidden" }
                        : { justifySelf: "flex-start" }
                    }
                    onClick={decreaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                      }
                    >
                      {"<"}
                    </span>
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    type="button"
                    aria-label="Next Month"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--next"
                    }
                    style={
                      customHeaderCount === 0
                        ? { visibility: "hidden" }
                        : { justifySelf: "flex-end" }
                    }
                    onClick={increaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                      }
                    >
                      {">"}
                    </span>
                  </button>
                </div>
              </div>
            )}
            popperPlacement="bottom"
            {...datePickerProps}
          />
        );
      }}
    />
  );
};
