import React from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";

const customSelectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 2 }),
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: "white",
      borderColor: "var(--lightGrey)",
      boxShadow: "var(--darkGrey)",
      "&:hover": {
        borderColor: isFocused ? "var(--darkGrey)" : "",
      },
      borderRadius: "none",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "var(--darkGrey)"
        : isFocused
        ? "var(--lightGrey)"
        : "white",
      color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      zIndex: 2,

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "var(--darkGrey)"
          : undefined,
      },
    };
  },
  input: (styles) => {
    return { ...styles };
  },
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles, fontSize: "1.4rem" }),
};

export const ControlledReactSelect = ({
  fieldName,
  control,
  className,
  options,
  defaultValue,
  customOnChange,
  isMulti,
  customStyles,
  ...selectProps
}) => {
  const customStyles_ = { ...customSelectStyles, ...customStyles };
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={fieldName}
      render={({ field: { onChange, value } }) => {
        return (
          <ReactSelect
            className={className}
            value={value}
            onChange={(option) => {
              if (customOnChange) {
                customOnChange(onChange, option);
              } else {
                onChange(option);
              }
            }}
            isMulti={isMulti}
            styles={customStyles_}
            options={options}
            {...selectProps}
          />
        );
      }}
    />
  );
};
