import styled from "styled-components";
import { device } from "../../../styles/deviceSizes";

export const ContactUsModalWrapper = styled.div`
  .contact-us-form {
    height: fit-content;
    display: inline-block;
    width: 100%;
    padding: 5%;
    .input,
    input,
    textarea {
      border: 1px solid var(--lightGrey);
    }
    .input,
    input {
      padding: 1rem 1.5rem;
    }
    .react-tel-input {
      input {
        border-color: var(--lightGrey);
        min-height: 4rem;
        max-height: 4rem;
        border-radius: unset;
        width: 100%;
      }
    }
    .form-control {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;
    }
    label {
      text-align: left;
      text-transform: capitalize;
      color: #3d3d3d;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 1rem;
      @media ${device.onlyMobileSm} {
        font-size: 1.2rem;
      }
    }
    .react-select {
      input {
        padding: 0.18rem !important;
        min-height: unset;
      }
    }
    .input {
      border-color: var(--lightGrey);
      min-height: 4rem;
      max-height: 4rem;
      font-size: 1.4rem;
      border-radius: unset;
      width: 100%;
      :hover {
        cursor: pointer;
      }
    }

    margin-top: 1rem;
    .submit-btn {
      width: 100%;
    }
  }
`;
export const SvgWrapper = styled.div`
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: black;
  }
  :hover {
    cursor: pointer;
  }
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    .react-datepicker__header {
      border: unset;
      background: transparent;
    }
    width: 100%;
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 5rem;

    .react-datepicker__month-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: calc(100% - 120px);
      @media ${device.onlyMobileSm} {
        width: calc(100% - 85px);
      }
    }

    .react-datepicker__day-names {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    .react-datepicker__day-name {
      font-size: 1.2rem;
      height: 100%;
      font-weight: 600;
      width: 100%;
      margin: unset;
      color: #333333;
    }

    .react-datepicker__current-month {
      font-size: 1.4rem;
      font-weight: 600;
    }

    .react-datepicker__month {
      margin: unset;
    }
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .react-datepicker__day {
      width: 100%;
      height: 100%;
      margin: unset;
      position: relative;
      width: 4rem;
      height: 4rem;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border: 0px;
      text-decoration: none;
      background: none;
      color: #333333;
      /* padding: 0.5rem; */
      @media ${device.onlyTableSm} {
        width: 3rem;
        height: 3rem;
        padding: 0.8rem;
      }
      @media ${device.mobileXL} {
        width: 100%;
        height: 100%;
        padding: 1rem;
      }
      @media ${device.mobileL} {
        width: 100%;
        height: 100%;
        padding: 0.6rem;
      }
      @media ${device.mobileS} {
        width: 100%;
        height: 100%;
        padding: 0.4rem;
      }
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day--selected {
      background: var(--darkGrey);
      color: white;
    }
    .react-datepicker__time-list {
      font-size: 1.4rem;
      ::-webkit-scrollbar {
        width: 0.5rem;
        @media ${device.onlyMobileSm} {
          width: 0.5rem;
        }
      }

      ::-webkit-scrollbar-track {
        background-color: #efefef;
      }

      ::-webkit-scrollbar-thumb {
        /* border-radius: 10px; */
        background-color: var(--grey73);
        height: 2px;
        border: 1px solid transparent;
        background-clip: content-box;
      }
    }
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--darkGrey);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker-popper {
    width: 90%;
    margin-bottom: 2rem;
  }
  .react-datepicker__time-list-item {
    font-size: 14px;
  }
  .react-datepicker__time-container {
    width: 120px;
    @media ${device.onlyMobileSm} {
      width: 85px;
    }
    .react-datepicker__time-box {
      width: unset !important;
      margin: 0;
    }
  }

  .react-datepicker__navigation--next {
    right: 12rem !important;
    @media ${device.onlyMobileSm} {
      right: 8rem !important;
    }
  }
`;
