import { gql } from "@apollo/client";

export const Fragment_MainImage = gql`
  fragment Fragment_MainImage on MainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      url
    }
    colorType
  }
`;

const Fragment_MonthlyPrice = gql`
  fragment Fragment_MonthlyPrice on MonthlyPrice {
    normalPrice {
      price
      note
    }
    seasonalPrice {
      price
      startDate
      endDate
    }
  }
`;

export const Fragment_RateModel = gql`
  fragment Fragment_RateModel on RateModel {
    monthlyPricing {
      january {
        ...Fragment_MonthlyPrice
      }
      february {
        ...Fragment_MonthlyPrice
      }
      march {
        ...Fragment_MonthlyPrice
      }
      april {
        ...Fragment_MonthlyPrice
      }
      may {
        ...Fragment_MonthlyPrice
      }
      june {
        ...Fragment_MonthlyPrice
      }
      july {
        ...Fragment_MonthlyPrice
      }
      august {
        ...Fragment_MonthlyPrice
      }
      september {
        ...Fragment_MonthlyPrice
      }
      october {
        ...Fragment_MonthlyPrice
      }
      november {
        ...Fragment_MonthlyPrice
      }
      december {
        ...Fragment_MonthlyPrice
      }
    }
  }
  ${Fragment_MonthlyPrice}
`;
