import React, { useEffect } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { TravelPlanWrapper, SvgWrapper } from "./elements";
import { useScrollToRef } from "../../../hooks";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import { Button } from "../../Button";
import { usePricingContext } from "../../Providers";
import { TRAVEL_PLAN } from "../../../constants";
import { RoomCard } from "./RoomCard";

export const TravelPlan = ({ onNextClick }) => {
  const {
    pricingState: {
      travelPlan: travelPlan_,
      allResorts,
      allRooms,
      pricingMode,
    },
    dispatch,
  } = usePricingContext();

  const travelPlan = { ...travelPlan_ };
  const { elementRef, executeScroll } = useScrollToRef();

  //@TODO WILL GET FROM THE CONTEXT API
  const defaultValues = {
    rooms: [...travelPlan.rooms],
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    // watch,
    // register,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rooms",
  });

  const watchRoomFields = useWatch({ control, name: "rooms" });
  const controlledRoomFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchRoomFields[index],
    };
  });

  useEffect(() => {
    executeScroll(elementRef);
  }, [controlledRoomFields?.length]);

  const buttonStyle = {
    margin: "1rem 0 0 0",
    width: "100%",
    color: "var(--doveGrey)",
    borderColor: "var(--lightGrey)",
  };

  const saveData = (data) => {
    const rooms = data?.rooms;
    dispatch({ type: TRAVEL_PLAN, payload: { rooms } });
    onNextClick?.();
  };

  return (
    <TravelPlanWrapper>
      <form
        onClick={(e) => {
          e.stopPropagation();
        }}
        onSubmit={handleSubmit(saveData)}
      >
        {controlledRoomFields?.map((item, index) => {
          return (
            <RoomCard
              index={index}
              item={item}
              control={control}
              setValue={setValue}
              isLastItem={
                index !== 0 &&
                item === controlledRoomFields[controlledRoomFields.length - 1]
              }
              getValues={getValues}
              remove={remove}
              elementRef={elementRef}
              allResorts={allResorts?.length ? allResorts : []}
              pricingMode={pricingMode}
              allRooms={allRooms}
            />
          );
        })}
        <div className="mt-2 d-flex align-items-center addroom-button">
          <div className="mr-1">Add another room</div>
          <SvgWrapper
            dimension="1.4rem"
            title="Add room"
            stroke="unset"
            onClick={() => {
              const dateOfTravel = getValues(`rooms.0.dateOfTravel`);

              append({
                room: undefined,
                dateOfTravel: dateOfTravel ? dateOfTravel : [],
                adults: 2,
                childrens: 0,
                monthlyPricing: null,
                childrensAge: [],
              });

              executeScroll(elementRef);
            }}
          >
            <PlusIcon />
          </SvgWrapper>
        </div>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "4rem",
            justifyContent: "flex-end",
          }}
          className="d-flex"
        >
          <Button variant="sm" styles={buttonStyle} hoverColor="var(--black)">
            Submit
          </Button>
        </div>
      </form>
    </TravelPlanWrapper>
  );
};
