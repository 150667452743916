import { useRef, createRef, useState, useLayoutEffect } from "react";

// @TODO rename this hook as we now using react-scroll to manage sections instead of refs
export const usePageSectionsRef = (sections) => {
  const sectionRefs = useRef({});
  const contentRefs = useRef({});
  const [navLinks, setNavLinks] = useState([]);
  useLayoutEffect(() => {
    const navLinks_ = sections.map(
      ({ name, isDropDown, content, ...restContent }) => {
        const content_ =
          content?.length &&
          content?.map(({ name, onClick }) => {
            return {
              name,
              onClick,
            };
          });
        return {
          name: name,
          content: isDropDown ? content_ : content,
          isDropDown,
          ...restContent,
        };
      }
    );
    setNavLinks(navLinks_);
  }, []);

  return { ...sectionRefs?.current, contentRefs: contentRefs, navLinks };
};
