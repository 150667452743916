import styled, { css } from "styled-components";
import { device } from "../../styles/deviceSizes";
const buttonSimple = css`
  transition: all 1s ease-in-out;
  width: ${({ variant }) => (variant === "sm" ? "12rem" : "15rem")};
  padding: ${({ variant }) =>
    variant === "sm" ? "1rem 0 1rem 0" : "1.2rem 0 1.2rem 0"};
  text-transform: uppercase;
  display: flex;
  border-color: var(--doveGrey);
  border: 1.5px solid var(--doveGrey);
  text-align: center;
  margin-top: 20px;
  color: ${({ isSelected }) => (isSelected ? "white" : "inherit")};
  font-size: 12px;
  justify-content: center;
  font-family: "rivera_light_regular", sans-serif;
  background: ${({ bgColor, isSelected }) =>
    bgColor ? bgColor : isSelected ? "var(--darkGrey)" : "none"};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "unset")};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    background: ${({ hoverColor }) =>
      hoverColor ? hoverColor : "var(--darkPrimary)"};
    color: #fff !important;
    border-color: white;
  }
  @media ${device.onlyMobileS} {
    font-size: 10px;
    padding: 10px 0px 10px 0px;
  }
`;

export const buttonStyles = css`
  cursor: pointer;
  ${({ kind }) => {
    switch (kind) {
      default:
        return css`
          ${buttonSimple}
        `;
    }
  }}
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;
